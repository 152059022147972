import Constants from "../../../Constants";
const schema = [
    {
      name: 'packer_plant_pork',
      displayName: 'Pork Establishment',
      systemIdField: 'id',
      displayIdField: 'name',
      path: `/packer_plants?product_type=${Constants.PRODUCT_TYPE_LIST.PORK}`,
      deletable: false,
      autoUid: true
    },
  ];
  export default schema;
  