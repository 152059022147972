import React, { useEffect, useState } from 'react';
import { Box, Button, VStack, Text } from '@chakra-ui/react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import '../../orders/order-table-modal/OrderActivityLog.scss';
import ContactBedge from '../importantContacts/ContactBedge';
import api from '../../../api';
import { DownloadOutlined } from '@ant-design/icons';

const columns = [
  {
    title: 'Edited By',
    dataIndex: 'edited_by',
    render: name => <ContactBedge name={name} />,
  },
  {
    title: 'Edit Type',
    dataIndex: 'edit_type',
  },
  {
    title: 'Edited Field',
    dataIndex: 'edited_field',
  },
  {
    title: 'Old Value',
    dataIndex: 'old_value',
    render: value => (!value || value === 'None' ? 'None' : <Box color="#FF4D49">{value}</Box>),
  },
  {
    title: 'New Value',
    dataIndex: 'new_value',
    render: value => (!value || value === 'None' ? 'None' : <Box color="#4CAF50">{value}</Box>),
  },
  {
    title: 'Entity Id',
    dataIndex: 'entity_id',
  },
];

const EstablishmentActivityLog = ({ establishmentDetailId }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getActivitiLogs = async (establishmentDetailId) => {
    setIsLoading(true);
    try {
      const response = await api.get(`/asl_history/${establishmentDetailId}`);

      const jsonData = await response.json();

      if (jsonData) {
        // group data by date
        const groupedData = jsonData.reduce((acc, curr) => {
          const date = moment(curr.modified_date).format('YYYY-MM-DD');
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(curr);
          return acc;
        }, {});

        setData(groupedData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (establishmentDetailId) {
      getActivitiLogs(establishmentDetailId);
    }
  }, [establishmentDetailId]);

  if (isLoading) {
    return (
      <Box padding="30px">
        <Box fontSize="20px" fontWeight="bold" align="center">
          Loading...
        </Box>
      </Box>
    );
  }

  const handleExport = async () => {
    try {
      const response = await api.get(`/asl_history_export/${establishmentDetailId}`, {
        responseType: 'blob',
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `activity_log_${establishmentDetailId}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  return (
    <>
      <Box marginBottom="15px" display="flex" justifyContent="space-between" alignItems="center">
        <Text color="rgba(0, 0, 0, 0.87)" fontSize="14px" fontWeight="600" lineHeight="1.43">
          Establishment Activity Logs
        </Text>
        <Button variant="solid" colorScheme="actionSecondary" onClick={handleExport}>
          Export to Excel
        </Button>
      </Box>
      
      {Object.keys(data).length > 0 ? (
        Object.keys(data).map(date => (
          <Box className="order-activity-logs" display="flex" alignItems="flex-start" marginBottom="35px" key={date}>
            <table className="order-activity-logs__date-and-time-table">
              <thead>
                <tr>
                  <th>{date}</th>
                </tr>
              </thead>
              <tbody>
                {data[date].map((item, index) => (
                  <tr key={index}>
                    <td>{moment(item.modified_date).format('hh:mm A')}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <VStack align="stretch" backgroundColor="card.default" width="100%" overflow="auto">
              <Table
                rowClassName="food-safety-table-row"
                className="food-safety-table"
                pagination={false}
                dataSource={data[date]}
                columns={columns}
              />
            </VStack>
          </Box>
        ))
      ) : (
        <Box padding="30px">
          <Box fontSize="20px" fontWeight="bold" align="center">
            No Activity Logs Found
          </Box>
        </Box>
      )}
    </>
  );
};

EstablishmentActivityLog.propTypes = {
  establishmentDetailId: PropTypes.string,
};

export default EstablishmentActivityLog;
