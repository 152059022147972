import Blob from 'blob';
import { saveAs } from 'file-saver';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import sumBy from 'lodash/sumBy';
import Constants from '../../Constants';
import { s2ab, sheetFromArrayOfArrays, Workbook } from '../../functions';
import { convertToSnakeCase } from '../../helpers';
import { isDeliveryDateRequired } from '../../utils';
import { getTenantFromDomain } from '../../helpers/tenant';

const { FORM_ATTRIBUTES } = Constants;

export function calculateNumberOfBins(po, props) {
  const poundsUom = props.units_of_measure.find(el => el.name === 'lbs');
  const poUom = props.units_of_measure.find(el => el.id === po.buyUnitOfMeasureId);
  const modifiedLines = [];

  po.lines.forEach(poLine => {
    const binSizePoUom = (poundsUom.conversion_rate * Constants.BIN_SIZE) / poUom.conversion_rate;
    const modifiedLine = { ...poLine };

    modifiedLine.buyQuantity = parseFloat(poLine.buyQuantity);
    modifiedLine.numberOfBins = Math.ceil(poLine.buyQuantity / binSizePoUom);
    modifiedLine.sellQuantity = parseFloat(poLine.buyQuantity);

    modifiedLines.push(modifiedLine);
  });

  return modifiedLines;
}

function shouldOmitField(po, key, orderType) {
  const formulaFields = ['formulaName', 'formulaMarket', 'formulaDay', 'formulaDaysAveraged', 'formulaBasis'];

  const recurringFields = ['recurringDateStart', 'recurringDate', 'ordersPerDayOfWeek'];

  const contractFields = ['contractStartDate', 'contractEndDate'];

  const domesticOrdersFields = ['deliveryDate'];

  const inernationalOrderFields = [
    'arrival_date_start',
    'arrival_date_end',
    'shipment_date_start',
    'shipment_date_end',
    'shipment',
    'arrival',
  ];

  return (
    (po.single_ingredient_form && recurringFields.includes(key)) ||
    (po.price_type !== 'formula' && formulaFields.includes(key)) ||
    (po.price_type !== 'contract' && contractFields.includes(key)) ||
    (!po.arrival && key.startsWith('arrival_')) ||
    (!po.shipment && key.startsWith('shipment_')) ||
    (orderType !== Constants.PO_FORM_TYPE.AUSTRALIA_DOMESTIC && domesticOrdersFields.includes(key)) ||
    (orderType !== Constants.PO_FORM_TYPE.AUSTRALIA_INTERNATIONAL && inernationalOrderFields.includes(key))
  );
}

export function prepareInternationalOrder(po, props) {
  const poToSend = {};

  Object.keys(po).forEach(key => {
    if (!shouldOmitField(po, key, props.formType)) {
      poToSend[key] = po[key];
    }
  });

  if (!po.id) {
    poToSend.lines.forEach(el => {
      delete el.expectedProductionDate;
      delete el.sellQuantity;
    });
  }

  if (poToSend.delivery_date) {
    delete poToSend.shipment;
    delete poToSend.arrival;
    delete poToSend.shipmentDateStart;
    delete poToSend.shipmentDateEnd;
    delete poToSend.arrivalDateStart;
    delete poToSend.arrivalDateEnd;
  }

  if (!poToSend.productType) {
    poToSend.productType = Constants.PRODUCT_TYPE_LIST.BEEF;
  }

  return poToSend;
}

export function formatInternationalPurchaseOrderLine(line, isChilled) {
  const spec = line.specifications || [];
  return {
    buy_price: line.buy_price,
    buy_price_per_unit: parseFloat(line.buy_price_per_unit),
    buy_quantity: parseFloat(line.buy_quantity),
    sell_quantity: parseFloat(line.sell_quantity),
    chilled: isChilled,
    frozen: !isChilled,
    input_product_uid: line.input_product_uid,
    sell_price: line.sell_price,
    sell_price_per_unit: line.sell_price_per_unit,
    expected_production_date: line.expected_production_date,
    active: line.active || true,
    package_count: line.package_count,
    package_type: line.package_type,
    line_number: line.line_number,
    specifications: spec || [],
  };
}

function validatePurchaseOrderFee(fee) {
  if (fee.currency) {
    return fee.estimate || fee.amount;
  }
  return false;
}

function formatPurchaseOrderFee(fee) {
  return {
    ...fee,
    estimate: fee.estimate ? parseFloat(fee.estimate) : null,
    amount: fee.amount ? parseFloat(fee.amount) : null,
  };
}

export function formatInternationalPurchaseOrder(po, form) {
  const formattedLines = Object.values(po.lines).map(line => {
    return formatInternationalPurchaseOrderLine(line, form.is_chilled);
  });
  const fees = get(form, 'fees', []);
  const validFees = fees.filter(validatePurchaseOrderFee).map(formatPurchaseOrderFee);
  const formattedPO = {
    ...(form.foreign_exchange_rate && { foreign_exchange_rate: form.foreign_exchange_rate }),
    ...(po.arrival_date_end && { arrival_date_end: po.arrival_date_end }),
    ...(po.arrival_date_start && { arrival_date_start: po.arrival_date_start }),
    ...(po.delivery_date && { delivery_date: po.delivery_date }),
    ...(po.scheduled_delivery_time && { scheduled_delivery_time: po.scheduled_delivery_time }),
    ...(po.preferred_eta && { preferred_eta: po.preferred_eta }),
    ...(po.shipment_date_end && { shipment_date_end: po.shipment_date_end }),
    ...(po.shipment_date_start && { shipment_date_start: po.shipment_date_start }),
    ...(po.estimated_delivery_date && { estimated_delivery_date: po.estimated_delivery_date }),
    // ...(po.arrival_date_start && { arrival: true }),
    // ...(po.shipment_date_start && { shipment: true }),
    arrival: po.date_type === 'arrival',
    buy_currency: form.buy_currency,
    buy_incoterms: form.buy_incoterms,
    buy_unit_of_measure_id: form.buy_unit_of_measure_id,
    buyer_id: form.buyer_id,
    confirmation_of_purchase_comments: form.confirmation_of_purchase_comments,
    confirmation_of_sale_comments: form.confirmation_of_sale_comments,
    copies: form.copies,
    external_comments: form.external_comments,
    fec_id: form.fec ? form.fec.id : undefined,
    fees: validFees,
    grinder_uid: form.grinder_uid,
    grinder_po_number: form.grinder_po_number,
    halal: form.halal,
    internal_comments: form.internal_comments,
    invoicing_office: Constants.BUSINESS_OFFICES.SYDNEY.value,
    is_coldstore_required: form.is_coldstore_required,
    is_shipment_date_required_prompt: po.isShipmentDateRequiredPrompt,
    lines: formattedLines,
    load_container_details: form.load_container_details,
    mtc: form.mtc,
    packer_id: form.packer_id,
    packer_plant_id: form.packer_plant_id,
    price_type: form.price_type,
    product_type: Constants.PRODUCT_TYPE_LIST.BEEF,
    purchasing_office: form.purchasing_office,
    sell_currency: form.sell_currency,
    sell_incoterms: form.sell_incoterms,
    sell_unit_of_measure_id: form.sell_unit_of_measure_id,
    shipment: po.date_type === 'shipment',
    status: form.status,
    tags: po.tags,
    voyage: form.voyage,
    ...(form.is_recurring && {
      days: po.days,
      recurringDateStart: po.recurring_date_start,
      recurringDateEnd: po.recurring_date_end,
    }),
    end_user_id: form.end_user_id,
  };
  return formattedPO;
}

export function prepareSydneyOrders(form) {
  const snaked = convertToSnakeCase(form);
  const formattedPOs = Object.values(snaked.purchase_orders).reduce((agg, po) => {
    const formattedPO = formatInternationalPurchaseOrder(po, snaked);

    let poList = [formattedPO];
    if (po.copies > 1) {
      poList = Array(po.copies).fill(formattedPO);
    }
    return [...agg, ...poList];
  }, []);

  return formattedPOs;
}
function calculateBuyPricePerUnit(productType, pricePerUnit, premiumRate, freightRate) {
  const parsedPricePerUnit = parseFloat(pricePerUnit) || 0;
  const parsedPremiumRate = parseFloat(premiumRate) || 0;
  const parsedFreightRate = parseFloat(freightRate) || 0;

  let buyPricePerUnit;
  if (productType === Constants.PRODUCT_TYPE_LIST.BEEF) {
    buyPricePerUnit = parsedPricePerUnit + parsedFreightRate;
  } else if (productType === Constants.PRODUCT_TYPE_LIST.PORK) {
    buyPricePerUnit = parsedPricePerUnit + parsedPremiumRate + parsedFreightRate;
  } else {
    // Return buyPricePerUnit (or 0) if the product type is neither
    buyPricePerUnit = parsedPricePerUnit || 0;
  }  
  // Return the buyPricePerUnit rounded to four decimal places
  return parseFloat(buyPricePerUnit.toFixed(4));
}
function makeLine(line, freightRate = 0, productType) {
  const lineToSend = {
    input_product_uid: line.inputProductUid,
    number_of_bins: parseFloat(line.numberOfBins),
    price_per_unit: parseFloat(line.pricePerUnit),
    group_housed_premium_rate:parseFloat(line.groupHousedPremiumRate).toFixed(4),
    expected_production_date: line.expectedProductionDate === '' ? null : line.expected_production_date,
    buy_quantity: line.buyQuantity,
    sell_quantity: line.sellQuantity,
    price_type: line.priceType,
  };

  // Use the productType from the purchase order
  lineToSend.buy_price_per_unit = calculateBuyPricePerUnit(
    productType,
    lineToSend.price_per_unit,
    lineToSend.group_housed_premium_rate,
    freightRate
  );


  if (line.priceType === 'formula') {
    lineToSend.formula_name = line.formulaName.split('|')[0];
    lineToSend.formula_market = line.formulaMarket;
    lineToSend.formula_day = Number(line.formulaDay);
    lineToSend.formula_days_averaged = Number(line.formulaDaysAveraged);
    lineToSend.formula_basis = parseFloat(line.formulaBasis);
  }
  if (line.priceType === 'contract') {
    lineToSend.contract_start_date = line.contractStartDate;
    lineToSend.contract_end_date = line.contractEndDate;
  }
  return lineToSend;
}

export function prepareAmericanOrder(po, props) {
  const poundsUom = props.units_of_measure.find(el => el.name === 'lbs');
  po.buyUnitOfMeasureId = poundsUom.id;
  po.sellUnitOfMeasureId = poundsUom.id;
  po.lines = calculateNumberOfBins(po, props);
  
  const freightRate = parseFloat(po.transportCostPerUnit);
  const productType = po.productType; // Get product type from the purchase order
  const lineItems = po.lines.map(line => {
    return makeLine(line,freightRate,productType);
  });
  return {
    packer_plant_id: po.packerPlantId,
    grinder_uid: po.grinderUid,
    transporter_id: po.transporterId || null,
    drop_trailer: po.dropTrailer || null,
    shipment_date: po.shipmentDate || null,
    delivery_date: po.deliveryDate || null,
    scheduled_delivery_time: po.scheduledDeliveryTime || null,
    production_date: po.productionDate || null,
    transport_cost_per_unit: parseFloat(po.transportCostPerUnit),
    truck_load_no: po.truckLoadNo || null,
    status: po.status,
    lines: lineItems,
    buyer_id: po.buyer_id,
    product_type: po.productType || Constants.PRODUCT_TYPE_LIST.BEEF,
    purchasing_office: Constants.BUSINESS_OFFICES.CHICAGO.value,
    invoicing_office: Constants.BUSINESS_OFFICES.CHICAGO.value,
    is_coldstore_required: false,
    buy_unit_of_measure_id: po.buyUnitOfMeasureId,
    sell_unit_of_measure_id: po.sellUnitOfMeasureId,
    end_user_id: po.endUserId,
  };
}

export function prepareRecurringOrder(po, props, orderType) {
  const objToReturn = {};

  po.ordersPerDayOfWeek.forEach((day, index) => {
    switch (index) {
      case 0:
        objToReturn.mon = Number(day);
        break;
      case 1:
        objToReturn.tue = Number(day);
        break;
      case 2:
        objToReturn.wed = Number(day);
        break;
      case 3:
        objToReturn.thu = Number(day);
        break;
      case 4:
        objToReturn.fri = Number(day);
        break;
      case 5:
        objToReturn.sat = Number(day);
        break;
      case 6:
        objToReturn.sun = Number(day);
        break;
      case 'default':
      default:
        objToReturn.mon = Number(day);
        break;
    }
  });

  const orderToSend =
    orderType === Constants.PO_FORM_TYPE.AMERICA_DOMESTIC
      ? prepareAmericanOrder(po, props)
      : prepareInternationalOrder(po, props);

  return {
    rules: {
      startDate: po.recurringDateStart,
      endDate: po.recurringDateEnd,
      weeklyLoads: objToReturn,
    },
    purchaseOrder: orderToSend,
  };
}

export function hasSelectedPackerAndGrinder({ packer, packerPlant, grinder }) {
  if (!packer || !packerPlant || !grinder) {
    return false;
  }
  return true;
}

export function isAmericanDomesticOrder({ packerPlant, grinder }) {
  return (
    Constants.AMERICA_DOMESTIC_COUNTRIES.includes(packerPlant?.originCountry?.toLowerCase()) &&
    Constants.AMERICA_DOMESTIC_COUNTRIES.includes(grinder?.destinationCountry?.toLowerCase())
  );
}

export function isAmericanOrderForm({ packer, packerPlant, grinder }) {
  return (
    hasSelectedPackerAndGrinder({ packer, packerPlant, grinder }) && isAmericanDomesticOrder({ grinder, packerPlant })
  );
}
export function isInternationalOrder({ packer, packerPlant, grinder }) {
  const hasSelected = hasSelectedPackerAndGrinder({ packer, packerPlant, grinder });
  const isAmerican = isAmericanOrderForm({ packer, packerPlant, grinder });

  if (!hasSelected || isAmerican) {
    return false;
  }

  const isDifferent = get(packer, 'address.country', '').toLowerCase() !== grinder.destinationCountry.toLowerCase();
  return hasSelected && !isAmerican && isDifferent;
}

export function isAustraliaDomesticOrder({ grinder, packerPlant }) {
  return (
    Constants.AUSTRALIA_DOMESTIC_COUNTRIES.includes(packerPlant?.originCountry?.toLowerCase()) &&
    Constants.AUSTRALIA_DOMESTIC_COUNTRIES.includes(grinder?.destinationCountry?.toLowerCase())
  );
}

export function isEuropeDomesticOrder({ grinder, packerPlant }) {
  return (
    Constants.EUROPEAN_DOMESTIC_LIST.includes(packerPlant?.originCountry?.toLowerCase()) &&
    Constants.EUROPEAN_DOMESTIC_LIST.includes(grinder?.destinationCountry?.toLowerCase())
  );
}

export function isGeneralDomesticOrder({ grinder, packerPlant }) {
  return packerPlant?.originCountry?.toLowerCase() === grinder?.destinationCountry?.toLowerCase();
}

export function checkIfVoyageDetailsRequired({ isAmericanDomestic, isAustralianDomestic, isGeneralDomestic, isEuropeDomestic }) {
  if (isAmericanDomestic || isAustralianDomestic || isGeneralDomestic || isEuropeDomestic) return false;
  return true;
}

export function analyzeBasePOForm({ packer, grinder, packerPlant }) {
  const isReady = hasSelectedPackerAndGrinder({ packer, packerPlant, grinder });

  let isAustralianDomestic = false;
  let isAmericanDomestic = false;
  let isEuropeDomestic = false;
  let isGeneralDomestic = false;

  if (isReady) {
    // When packer, grinder, and packerPlant are AUS or NZ
    isAustralianDomestic = isAustraliaDomesticOrder({ packer, grinder, packerPlant });
    isEuropeDomestic = isEuropeDomesticOrder({ packer, grinder, packerPlant });
    // When packer, grinder, and packerPlant are USA or Canada
    isAmericanDomestic = isAmericanDomesticOrder({ grinder, packerPlant });
    // When packer, grinder, and packerPlant are in same country
    isGeneralDomestic = isGeneralDomesticOrder({ grinder, packerPlant });
  }

  const attributes = {
    [FORM_ATTRIBUTES.HAS_PACKER_AND_GRINDER]: isReady,
    [FORM_ATTRIBUTES.HAS_VOYAGE_DETAILS]: checkIfVoyageDetailsRequired({
      isAmericanDomestic,
      isAustralianDomestic,
      isGeneralDomestic,
      isEuropeDomestic
    }),
    [FORM_ATTRIBUTES.IS_AMERICAN_DOMESTIC]: isAmericanDomestic,
    [FORM_ATTRIBUTES.IS_AUSTRALIAN_DOMESTIC]: isAustralianDomestic,
    [FORM_ATTRIBUTES.IS_EUROPE_DOMESTIC]: isEuropeDomestic,
    [FORM_ATTRIBUTES.IS_GENERAL_DOMESTIC]: isGeneralDomestic,
  };
  return attributes;
}

export function checkIfChicagoForm({ invoicingOffice, purchasingOffice }) {
  const CHICAGO = Constants.BUSINESS_OFFICES.CHICAGO.value;
  if (invoicingOffice === CHICAGO && purchasingOffice === CHICAGO) {
    return true;
  }
  return false;
}

function checkIfDefined(value) {
  return value && value !== '';
}

function checkValuesByKeyList(obj, keyList) {
  return keyList.reduce((agg, key) => {
    const isDefined = checkIfDefined(obj[key]);
    if (!isDefined) {
      return [...agg, key];
    }
    return agg;
  }, []);
}

// TODO check if correct
export function checkForQuantityError(purchaseOrdersList, unitOfMeasure) {
  const quantityChecks = purchaseOrdersList.map(po => {
    const quantitySum = sumBy(Object.values(po.lines), line => line.sellQuantity);

    switch (unitOfMeasure) {
      case 'kgs':
        return quantitySum > 24500;
      case 'lbs':
        return quantitySum > 54013;
      default:
        return false;
    }
  }, []);

  const errors = quantityChecks.filter(Boolean);
  if (!isEmpty(errors)) return true;
  return false;
}

function validateRequiredSydneyOrderFormFields(form) {
  const commonFields = ['endUserId', 'priceType'];
  switch (form.priceType) {
    case 'spot': {
      const checkFXRate = form.sellCurrency && form.buyCurrency && form.sellCurrency !== form.buyCurrency;

      return checkValuesByKeyList(form, [
        ...commonFields,
        'buyIncoterms',
        'sellIncoterms',
        'buyCurrency',
        'buyUnitOfMeasureId',
        'sellUnitOfMeasureId',
        'sellCurrency',
        'loadContainerDetails',
        'packerId',
        'packerPlantId',
        'grinderUid',
        'priceType',
        ...(checkFXRate ? ['fec'] : []),
      ]);
    }
    case 'formula':
      return checkValuesByKeyList(form, [...commonFields, 'formulaMarket', 'formulaBasis']);
    case 'contract':
      return checkValuesByKeyList(form, [...commonFields, 'contractStartDate', 'contractEndDate']);
    default:
      return checkValuesByKeyList(form, [...commonFields]);
  }
}

function validateRequiredPurchaseOrderFields(po, configs) {
  const deliveryDateRequired = isEmpty(configs) ? true : isDeliveryDateRequired(configs);
  switch (po.dateType) {
    case 'shipment':
      return !po.isShipmentDateRequiredPrompt || po.isShipmentDateRequiredPrompt == null
        ? checkValuesByKeyList(po, deliveryDateRequired ? ['shipmentDateStart', 'shipmentDateEnd'] : [])
        : [];
    case 'arrival':
      return !po.isShipmentDateRequiredPrompt || po.isShipmentDateRequiredPrompt == null
        ? checkValuesByKeyList(po, ['arrivalDateStart', 'arrivalDateEnd'])
        : [];
    case 'recurring': {
      const dayKeys = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
      const days = checkValuesByKeyList(po.days, dayKeys);
      const isMissingDay = days.length === dayKeys.length;
      const dayErrors = isMissingDay ? ['days'] : [];
      const recurringDateErrors = checkValuesByKeyList(po, ['recurringDateStart', 'recurringDateEnd']);
      return [...recurringDateErrors, ...dayErrors];
    }
    default:
      return checkValuesByKeyList(po, po.isColdstoreRequired && !deliveryDateRequired ? [] : ['deliveryDate']);
  }
}

function validateRequiredPurchaseOrderLineFields(line) {
  if (!line.active) return [];

  return checkValuesByKeyList(line, ['buyQuantity', 'buyPricePerUnit', 'inputProductUid', 'sellQuantity']);
}

function validateSydneyOrderChildForms(form, childName) {
  if (form[childName]) {
    const partialFieldsOnRowNo = [];
    // check if the child comp is partially filled
    form[childName].forEach((obj, indx) => {
      if (Object.values(obj).includes('', null)) {
        partialFieldsOnRowNo.push(indx + 1);
      }
    });
    return { [childName]: partialFieldsOnRowNo };
  }
  return [];
}

export const defaultRequiredSydneyPOFormLabels = [
  'purchasingOffice',
  'priceType',
  'productType',
  'packerId',
  'packerPlantId',
  'grinderUid',
];

/* function that checks for required fields 
  on international edit form
  parts of code duplicates in validateRequiredFields */
export function validateEditOrderForm(form, configs) {
  const requiredFormFieldErrors = validateRequiredSydneyOrderFormFields(form);
  const requiredPOFieldErrors = validateRequiredPurchaseOrderFields(form, configs);
  const formErrors = requiredFormFieldErrors.concat(requiredPOFieldErrors);

  if (form.onHold && !form.onHoldReason) {
    formErrors.push('onHoldReason');
  } else if (!form.onHold || form.onHoldReason) {
    formErrors.splice(formErrors.indexOf('onHoldReason'), 1);
  }

  const linesErrors = form.lines.reduce(
    (agg, line) => ({
      ...agg,
      [line.id]: validateRequiredPurchaseOrderLineFields(line),
    }),
    {}
  );
  return { formErrors, linesErrors };
}

/*
validates required_fields on create order [can be multiple orders]
sends validation errors with a boolean status
hasErrors and keys 
Pos for PO line fields
form for other fields on the form
*/
// TODO FixME return data structure, requiredFormFields arg not used
export function validateRequiredFields(form, requiredFormFields, uom, configs) {
  let hasErrors = false;

  const purchaseOrdersList = Object.values(get(form, 'purchaseOrders', {}));

  const formErrors = validateRequiredSydneyOrderFormFields(form);

  if (!isEmpty(formErrors)) {
    hasErrors = true;
  }

  const purchaseOrderErrors = purchaseOrdersList.reduce((agg, po) => {
    const errors = validateRequiredPurchaseOrderFields(po, configs);

    if (!isEmpty(errors)) {
      hasErrors = true;
    }

    const lineList = Object.values(po.lines);

    const linesErrors = lineList.reduce((agg, line) => {
      const errors = validateRequiredPurchaseOrderLineFields(line);

      if (!isEmpty(errors)) {
        hasErrors = true;
      }

      return {
        ...agg,
        [line.idx]: errors,
      };
    }, {});

    return {
      ...agg,
      [po.idx]: {
        lines: linesErrors,
        errors,
      },
    };
  }, {});

  const buyQuantityError = checkForQuantityError(Object.values(form.purchaseOrders), uom);

  return {
    form: formErrors,
    pos: purchaseOrderErrors,
    buyQuantity: buyQuantityError,
    error: hasErrors,
  };
}

export function getPurchaseOrderIds(purchaseOrders) {
  let purchaseOrderIds = '';
  if (purchaseOrders.length > 1) {
    purchaseOrderIds = [purchaseOrders[0].id, ' to ', purchaseOrders[purchaseOrders.length - 1].id].join('');
  } else {
    purchaseOrderIds = [purchaseOrders[0].id];
  }

  return purchaseOrderIds;
}

function prepareObjectToSend(obj) {
  const finalObject = {};
  const keys = Object.keys(obj);

  keys.forEach(key => {
    // Revert this if the fields shouldn't be isClearable
    // if (obj[key] && obj[key] !== '') {
    finalObject[key] = get(obj, key);
    // }
  });

  return finalObject;
}

export function checkObjectForValues(obj, fieldsToIgnore = []) {
  if (!obj) return false;
  const entries = Object.entries(obj).filter(([key, value]) => {
    if ((value !== 0 && !value) || ((value instanceof Object || value instanceof Array) && isEmpty(value))) {
      return false;
    }
    if (fieldsToIgnore.includes(key)) {
      return false;
    }
    return true;
  });
  return entries.length;
}

function preSelectColdStoreIDBasedOn(defaultColdStoreId, chosenColdStoreID) {
  let selectedColdStoreID = null;

  if (chosenColdStoreID) {
    selectedColdStoreID = chosenColdStoreID;
  } else {
    selectedColdStoreID = defaultColdStoreId;
  }

  return selectedColdStoreID;
}

export function prepareInternationalOrderPatch(order) {
  /*
   * NOTE: In the below payloot, coldstore details, BOL details,
   * shipment costs, and preferred_eta
   * are conditional fields that are only included in the payload if they have
   * been defined in the submitted form.
   */

  const {
    // packerId,
    arrival,
    arrivalDateEnd,
    arrivalDateStart,
    billOfLading,
    buyCurrency,
    buyerId,
    buyIncoterms,
    buyUnitOfMeasureId,
    coldstoreDetails,
    defaultColdstoreId,
    deliveryDate,
    documents,
    exportDetails,
    externalComments,
    fec,
    fees,
    finalDestination,
    grinder,
    halal,
    onHold,
    internalComments,
    onHoldReason,
    isReadyForPaymentUpdated,
    isShipmentDateRequiredPrompt,
    loadContainerDetails,
    mtc,
    packerInvoiceNumber,
    packerPaidDate,
    packerInvoiceDate,
    coldstorePlannedDeliveryDate,
    packerPlantId,
    packerSoNumber,
    preferredEta,
    readyForPayment,
    sellCurrency,
    sellIncoterms,
    sellUnitOfMeasureId,
    shipment,
    shipmentDate,
    shipmentDateEnd,
    shipmentDateStart,
    status,
    tags,
    endUserId,
    grinderPoNumber,
    recvDeliveryDate,
    scheduledDeliveryTime,
    transporterId,
    hedgeContractNumber,
    surcharges,
    references,
    additinalGrn,
    priceType,
    purchasingOffice,
    productType,
    estimatedDeliveryDate,
  } = order;

  const selectedColdStoreID = preSelectColdStoreIDBasedOn(defaultColdstoreId, coldstoreDetails.coldStoreId);

  const augmentedColdstoreDetails = {
    ...coldstoreDetails,
    coldStoreId: selectedColdStoreID,
    lotNumbers:
      coldstoreDetails.lotNumbers && coldstoreDetails.lotNumbers.length > 0
        ? coldstoreDetails.lotNumbers?.filter(ln => ln !== '')
        : [],
  };
  const hasColdstoreDetails = checkObjectForValues(augmentedColdstoreDetails, [
    'temperatureMeasurementUnitId',
    'inspectionDate',
  ]);
  const hasBillOfLading = checkObjectForValues(billOfLading);
  const validFees = fees.filter(validatePurchaseOrderFee).map(formatPurchaseOrderFee);
  const validRejectedCartons = get(order, 'rejectedCartons', []).filter(
    rc => checkValuesByKeyList(rc, Constants.REJECTED_CARTON_REQUIRED_FIELDS).length === 0
  );
  const validSurcharges = surcharges.filter(
    surcharge => checkValuesByKeyList(surcharge, Object.keys(Constants.INITIAL_SURCHARGES)).length === 0
  );
  const payload = {
    packerPlantId,
    // packerId,
    deliveryDate,
    sellUnitOfMeasureId,
    buyUnitOfMeasureId,
    buyIncoterms,
    sellIncoterms,
    buyCurrency,
    sellCurrency,
    // If there's a null value for fec, it means we've removed it explicitly.
    // Passing through the null id will dissassociate that FEC in the backend.
    // Otherwise, we'll pass through the ID or effectively not pass anything through
    fecId: fec === null ? null : fec ? fec.id : undefined,
    mtc,
    ...(hasColdstoreDetails && { coldstoreDetails: prepareObjectToSend(augmentedColdstoreDetails) }),
    ...(hasBillOfLading && { billOfLading: prepareObjectToSend(billOfLading) }),
    ...(preferredEta && { preferredEta }),
    rejectedCartons: validRejectedCartons,
    fees: validFees,
    loadContainerDetails,
    halal,
    onHold: onHold || false,
    onHoldReason,
    finalDestination,
    exportDetails,
    shipment,
    arrival,
    arrivalDateStart,
    shipmentDate,
    shipmentDateStart,
    shipmentDateEnd,
    arrivalDateEnd,
    buyerId,
    status,
    packerSoNumber,
    packerInvoiceNumber,
    packerInvoiceDate,
    packerPaidDate,
    coldstorePlannedDeliveryDate,
    internalComments,
    externalComments,
    documents,
    ...(isReadyForPaymentUpdated && {
      packerInvoiceStatus: {
        packerPlantId,
        orderShipmentType: arrival || shipment ? 'INTERNATIONAL' : 'DOMESTIC',
        readyForPayment: readyForPayment || false,
        isFundedGrinderOrder: !grinder.isUnfunded,
      },
    }),
    isShipmentDateRequiredPrompt:
      isShipmentDateRequiredPrompt !== null || isShipmentDateRequiredPrompt !== ''
        ? isShipmentDateRequiredPrompt
        : false,
    tags,
    endUserId,
    grinderPoNumber,
    recvDeliveryDate,
    scheduledDeliveryTime,
    transporterId,
    hedgeContractNumber,
    references,
    additinalGrn,
    surcharges: validSurcharges,
    estimatedDeliveryDate: estimatedDeliveryDate || null,
    ...(priceType && { priceType }),
    ...(purchasingOffice && { purchasingOffice }),
    ...(productType && { productType }),
  };
  if (payload.coldstoreDetails) {
    delete payload.coldstoreDetails.coldStore;
  }

  if (payload.arrival) {
    payload.shipmentDateStart = null;
    payload.shipmentDateEnd = null;
  }

  if (payload.shipment) {
    payload.arrivalDateStart = null;
    payload.arrivalDateEnd = null;
  }

  if (payload.shipment || payload.arrival) {
    // delete payload.deliveryDate;
  }
  // Make sure all json is snake_cased
  const snaked = convertToSnakeCase(payload);
  return snaked;
}

export function exportToExcelFile(order, props) {
  const worksheetName = 'Purchase Order';
  const tenant = getTenantFromDomain();
  const domain = tenant.includes('cfc') ? 'CFCO' : 'FMG';
  const poLabel = domain === 'CFCO' ? 'CFC PO#' : 'FMG PO#';
  const soLabel = domain === 'CFCO' ? 'CFC SO#' : 'FMG SO#';
  const data = [
    [
      'User',
      poLabel,
      soLabel,

      'Packer',
      'Packer Plant',
      'Packer SO#',

      'Grinder',
      'Grinder Plant',
      'Grinder PO#',

      'Order Date',
      'Delivery Date',

      'Shipment Date Start',
      'Shipment Date End',

      'Arrival Date Start',
      'Arrival Date End',

      'Halal Required',
      'MTC',

      'Buy Incoterms',
      'Sell Incoterms',

      'Buy UOM',
      'Sell UOM',

      'Buy Currency',
      'Sell Currency',

      'FX Rate',

      'Status',

      'PO Price',
      'PO Quantity',

      'PO Modified',

      'Line Number',
      'Line Product',

      'Line Chilled',
      'Line Frozen',
      'Line FMG Angus',
      'Line USDA Angus',
      'Line Grain Fed',
      'Line Grass Fed',
      'Line Wagyu',

      'Line Quantity',
      'Line Buy Price Per Unit',
      'Line Sell Price Per Unit',

      'Line Expected Production Date',

      'Line Active',
    ],
  ];

  if (typeof order.lines !== 'undefined') {
    let poPacker = false;
    props.packerPlants.forEach(packerPlant => {
      if (order.packerPlantId === packerPlant.id) {
        poPacker = packerPlant;
      }
    });

    let poGrinder = false;
    props.grinders.forEach(grinder => {
      if (order.grinderUid === grinder.uid) {
        poGrinder = grinder;
      }
    });

    let buyIncoterms = false;
    props.incoTerms.forEach(el => {
      if (order.buyIncoterms === el.value) {
        buyIncoterms = el;
      }
    });

    let buyUOM = false;
    props.unitsOfMeasure.forEach(el => {
      if (order.buyUnitOfMeasureId === el.id) {
        buyUOM = el;
      }
    });

    let sellUOM = false;
    props.unitsOfMeasure.forEach(el => {
      if (order.sellUnitOfMeasureId === el.id) {
        sellUOM = el;
      }
    });

    let sellIncoterms = false;
    props.incoTerms.forEach(el => {
      if (order.sellIncoterms === el.value) {
        sellIncoterms = el;
      }
    });

    order.lines.forEach(el => {
      // const inputProduct = props.inputProducts.find(input => input.uid === el.inputProductUid);
      const excelRow = [
        props.user.email,
        order.internalPoNumber,
        order.internalSoNumber,

        poPacker.name,
        poPacker.id,
        order.packerSoNumber,

        poGrinder.name,
        poGrinder.uid,
        order.grinderPoNumber,

        order.orderDate.split('T')[0],
        order.deliveryDate,

        order.shipmentDate,
        order.shipmentDateEnd,

        order.arrivalDateStart,
        order.arrivalDateEnd,

        order.halal,
        order.mtc,

        buyIncoterms.name,
        sellIncoterms.name,

        buyUOM.name,
        sellUOM.name,

        order.buyCurrency,
        order.sellCurrency,

        order.fec?.fecRate,
        order.status,

        order.price,
        order.buyQuantity,
        order.modified,

        el.lineNumber,
        el.inputProductUid,

        el.chilled,
        el.frozen,
        el.fmgAngus,
        el.usdaAngus,
        el.grainFed,
        el.grassFed,
        // el.sustainable,
        el.wagyu,

        el.buyQuantity,
        el.buyPricePerUnit,
        el.sellPricePerUnit,
        el.expectedProductionDate,
        el.active,
      ];
      data.push(excelRow);
    });
  }

  const excelWorkbook = new Workbook();

  const excelWorksheet = sheetFromArrayOfArrays(data);
  excelWorkbook.SheetNames.push(worksheetName);
  excelWorkbook.Sheets[worksheetName] = excelWorksheet;
  const aboutWorkbook = window.XLSX.write(excelWorkbook, {
    bookType: 'xlsx',
    bookSST: true,
    type: 'binary',
  });
  saveAs(new Blob([s2ab(aboutWorkbook)], { type: 'application/octet-stream' }), 'Single Product Order.xlsx');
}
