const schema = [
  {
    name: 'issues_xcode',
    displayName: 'Load Note',
    systemIdField: 'id',
    displayIdField: 'name',
    tabGroup: { key: 'system_settings', label: 'System Settings' },
    deletable: false,
    autoUid: true,
    path: '/issues_xcodes',
    fields: [
      {
        name: 'id',
        displayName: 'ID',
        dataType: 'text',
        required: false,
        systemIdentifier: true,
        filterable: true,
        hidden: true,
      },
      {
        name: 'category',
        displayName: 'Category',
        dataType: 'text',
        required: true,
        systemIdentifier: false,
      },
      {
        name: 'code',
        displayName: 'Reason',
        dataType: 'shorttext',
        required: true,
        systemIdentifier: false,
      },
      {
        name: 'delisted',
        displayName: 'Is Delisted',
        dataType: 'boolean',
      },
    ],
  },
];
export default schema;
