import {
  Box,
  Button,
  ButtonGroup,
  FormLabel,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Text,
  Switch,
  VStack,
  HStack,
  Wrap,
  Flex,
  Tag,
} from '@chakra-ui/react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
// Local Deps
import { fetchConfigs } from '../../../../actions/actions_config';
import Constants from '../../../../Constants';
import QuantityFields from '../../../../containers/orders/QuantityFields';
import { checkQuantityValidity } from '../../../../functions';
import {
  // getInternalPoLabel,
  isManualPriceStrategyByConfigsAndEndUser,
  isPoHedgingEnabled,
  isQuantityReceivedEditable,
} from '../../../../utils';
import SelectField from '../../../basic/SelectField';
import ProductionDetails from '../ProductionDetails/ProductionDetails';
import { convertCartonsToUnitOfMeasure, lookupUomName } from './helpers';
// import { fetchFormFields } from '../../../../actions/actions_form_fields';
import './InternationalOrderLine.scss';
import { ReactComponent as TrashOutlineIconSVG } from '../../../../assets/trash-outline-icon.svg';
import CustomFormControl from '../../../core/CustomFormControl';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { convertToSnakeCase } from '../../../../helpers';
import POFormFieldLabel from '../edit-form/POFormFieldLabel';
import DateTimeWithTrappedFocus from '../../../core/DateTimeWithTrappedFocus';
import { fetchSpecifications } from '../../../../actions/actions_specifications';
import PriceAdjustment from '../PriceAdjustment/PriceAdjustment';
import CL from '../CL';
import Temperatures from '../Temperature';
import { id } from 'date-fns/locale';

class InternationalOrderLine extends PureComponent {
  static propTypes = {
    buyCurrency: PropTypes.string,
    buyUnitOfMeasureId: PropTypes.number,
    destinationCountry: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    formType: PropTypes.string,
    form: PropTypes.object,
    ingredientsOptions: PropTypes.arrayOf(PropTypes.object),
    isChilled: PropTypes.bool.isRequired,
    isInEditMode: PropTypes.bool.isRequired,
    hasExpectedProductionDate: PropTypes.bool.isRequired,
    lineIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
    line: PropTypes.shape({
      buyQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      buyPricePerUnit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      expectedProductionDate: PropTypes.string,
      idx: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      inputProductUid: PropTypes.string,
      specifications: PropTypes.arrayOf(PropTypes.object),
      sellQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      sellPricePerUnit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      cosPricePerUnit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      productionDetails: PropTypes.arrayOf(PropTypes.object),
      packageType: PropTypes.string,
      packageCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      packerPlantId: PropTypes.string,
      packerIngredientDescription: PropTypes.string,
      recvQuantity: PropTypes.number,
      hedgeItems: PropTypes.arrayOf(PropTypes.object),
      fobPricePerUnit: PropTypes.string,
      orderType: PropTypes.string,
      priceType: PropTypes.string,
      orderTypeNotes: PropTypes.string,
      standing: PropTypes.string,
      active: PropTypes.bool,
      formulaName: PropTypes.string,
      formulaMarket: PropTypes.string,
      formulaDay: PropTypes.string,
      formulaDaysAveraged: PropTypes.string,
      formulaBasis: PropTypes.string,
      contractStartDate: PropTypes.string,
      contractEndDate: PropTypes.string,
      pricePerUnit: PropTypes.string,
      groupHousedPremiumRate: PropTypes.number,
      inputProduct: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        cl: PropTypes.string,
      }),
      temperatures: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        temperature: PropTypes.string,
      })),
      clResults: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        clvalue: PropTypes.string,
      })),
      opTempType: PropTypes.string,
      opClType: PropTypes.string,
      opAverageThirdPartyClResult: PropTypes.string,
      opTestresult: PropTypes.string,

    }).isRequired,
    lines: PropTypes.instanceOf(Object).isRequired,
    loadSizes: PropTypes.arrayOf(PropTypes.object).isRequired,
    onAddLine: PropTypes.func.isRequired,
    onPPUChangeValue: PropTypes.func.isRequired,
    onPPURoundingDecimal: PropTypes.func.isRequired,
    onRemoveLine: PropTypes.func.isRequired,
    onUpdateLine: PropTypes.func.isRequired,
    originCountry: PropTypes.string.isRequired,
    packerId: PropTypes.string.isRequired,
    packerPlantId: PropTypes.string.isRequired,
    purchaseOrderId: PropTypes.number,
    purchasingOffice: PropTypes.string,
    purchaseOrderIndex: PropTypes.number.isRequired,
    requiredFields: PropTypes.instanceOf(Object).isRequired,
    requiredFieldsLabels: PropTypes.instanceOf(Object).isRequired,
    sellCurrency: PropTypes.string,
    sellUnitOfMeasureId: PropTypes.number,
    setErrorState: PropTypes.func.isRequired,
    shipmentDateEnd: PropTypes.string,
    submitProductionDetails: PropTypes.func,
    submitPriceAdjustments: PropTypes.func,
    tags: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.instanceOf(Object),
    token: PropTypes.string.isRequired,
    unitsOfMeasure: PropTypes.arrayOf(PropTypes.object).isRequired,
    configs: PropTypes.arrayOf(PropTypes.object),
    endUsers: PropTypes.arrayOf(PropTypes.object),
    productSpecifications: PropTypes.arrayOf(PropTypes.object),
    packageWeights: PropTypes.arrayOf(PropTypes.object),
    isUSADomestic: PropTypes.bool,
    isChicagoForm: PropTypes.bool,
    priceTypes: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    ingredientsOptions: [],
  };

  state = {
    activeIndex: false,
    isCartonWeightRequired: true,
    expandHedgeItems: false,
  };

  componentDidMount() {
    const {
      isChilled,
      line,
      ingredientsOptions,
      packerPlantId,
      unitsOfMeasure,
      buyUnitOfMeasureId,
      purchasingOffice,
      productSpecifications,
      packageWeights,
      dispatch,
      configs,
      user,
      isLocked
    } = this.props;

    // Update buy quantity for frozen products when has default carton count
    if (configs.length === 0) {
      dispatch(fetchConfigs(user.token));
    }

    if (!ingredientsOptions.find(igdt => igdt.value === line.inputProductUid) && line.inputProductUid) {
      ingredientsOptions.push({
        value: line.inputProductUid,
        delisted: true,
        label: line.inputProduct.name,
        cl: line.inputProduct.cl,
      });
    }
    // default quantity for AACPW is 1 carton, 25kgs, 55lbs
    if (packerPlantId && packerPlantId === 'AACPW') {
      if (!line.packageCount) {
        line.packageCount = 1;
      }
      if (line.packageCount && !isChilled) {
        const ingredient = ingredientsOptions.find(igdt => igdt.value === line.inputProductUid);
        const unitOfMeasure = lookupUomName(unitsOfMeasure, buyUnitOfMeasureId);
        const buyQuantity = convertCartonsToUnitOfMeasure(
          line.packageCount,
          unitOfMeasure,
          // Create a shell of an input product from the ingredient options
          // This is enough to fool converCartonsToUnitOfMeasure
          { uid: ingredient?.value },
          packerPlantId,
          purchasingOffice,
          packageWeights
        );
        this.handleUpdateLine('buyQuantity', line.idx, buyQuantity);
      }
    }
    if (!line.buyPricePerUnit) {
      this.handleOnBlur('buyPricePerUnit', line.idx, 0);
    }
    if (!line.sellPricePerUnit) {
      this.handleOnBlur('sellPricePerUnit', line.idx, 0);
    }
    if (productSpecifications.length === 0) {
      dispatch(fetchSpecifications(user.token));
    }
  }

  componentDidUpdate(prevProps) {
    // Frozen UI toggle is interacted
    const { isChilled, line } = this.props;
    if (prevProps.line.packageCount !== line.packageCount) {
      this.calculateBuyAndSellQuantity();
    }
    if (prevProps.isChilled !== isChilled) {
      this.resetPackageTypeOn(isChilled, line);
      this.resetPackageCountOn(isChilled, line);
      if (isChilled) {
        this.setState({ isCartonWeightRequired: true });
      }
    }
  }

  calculateBuyAndSellQuantity = () => {
    const {
      line,
      ingredientsOptions,
      packerPlantId,
      unitsOfMeasure,
      buyUnitOfMeasureId,
      purchasingOffice,
      packageWeights,
    } = this.props;
    if (!ingredientsOptions.find(igdt => igdt.value === line.inputProductUid) && line.inputProductUid) {
      ingredientsOptions.push({
        value: line.inputProductUid,
        delisted: true,
        label: line.inputProduct.name,
        cl: line.inputProduct.cl,
      });
    }
    const ingredient = ingredientsOptions.find(igdt => igdt.value === line.inputProductUid);
    const unitOfMeasure = lookupUomName(unitsOfMeasure, buyUnitOfMeasureId);
    if (line.packageCount !== 0) {
      const buyQuantity = convertCartonsToUnitOfMeasure(
        line.packageCount,
        unitOfMeasure,
        // Create a shell of an input product from th ingredient options
        // This is enough to fool converCartonsToUnitOfMeasure
        { uid: ingredient?.value },
        packerPlantId,
        purchasingOffice,
        packageWeights
      );
      this.handleUpdateLine('buyQuantity', line.idx, buyQuantity);
    }
  };

  resetPackageTypeOn = (isChilled, line) => {
    const { idx } = line;
    const packageType = !isChilled ? Constants.PACKAGE_TYPE.CARTON : Constants.PACKAGE_TYPE.BIN;

    this.handleUpdateLine('packageType', idx, packageType);
  };

  // TODO - temporary solution to ensure that packageCount for cartons are not saved to the db
  // if the order is a fresh load
  resetPackageCountOn = (isChilled, line) => {
    const { idx } = line;

    if (isChilled) {
      this.handleUpdateLine('packageCount', idx, 0);
    }
  };

  onSelectActiveLine = () => {
    const { activeIndex } = this.state;
    this.setState({ activeIndex: !activeIndex });
  };

  handleAddLine = () => {
    this.props.onAddLine();
  };

  handlePackageCount = (packageCountKey, lineIndex, value) => {
    this.handleUpdateLine(packageCountKey, lineIndex, value);
  };

  handleUpdateLine = (fieldName, lineIndex, option) => {
    const {
      buyUnitOfMeasureId,
      setErrorState,
      lines,
      originCountry,
      destinationCountry,
      loadSizes,
      onUpdateLine,
      shipmentDateEnd,
    } = this.props;
    const value = get(option, 'value', option);
    if (fieldName === 'buyQuantity') {
      const buyQuantityError = checkQuantityValidity({
        fieldName,
        originCountry,
        destinationCountry,
        lines,
        loadSizes,
        buyUnitOfMeasureId,
        lineBuyQuantity: value,
        lineIndex,
      });
      // TODO change it to accept string, not boolean for consistency
      if (buyQuantityError) {
        return setErrorState('buyQuantity', true);
      }
      setErrorState('buyQuantity', false);
    }

    if (fieldName === 'expectedProductionDate' && shipmentDateEnd) {
      if (option > shipmentDateEnd) {
        return setErrorState('dateError', 'Expected production date cannot be after Shipment End date.');
      }
      setErrorState('dateError', null);
    }
    if (fieldName === 'inputProductUid') {
      this.props.line.packageCount = 0;
      this.props.line.buyQuantity = 0;
    }

    if (fieldName === 'specifications') {
      return onUpdateLine(
        lineIndex,
        fieldName,
        value?.map(el => {
          return el.value;
        })
      );
    }

    return onUpdateLine(lineIndex, fieldName, value);
  };

  handleRemoveLine = () => {
    const { line, onRemoveLine } = this.props;
    onRemoveLine(line.idx, line);
  };

  handleProductionDetailsChange = (
    lineIdx,
    lineId,
    productionDetails,
    toDeleteProductionDetailsIds,
    productionDetailsKey = 'productionDetails'
  ) => {
    const { submitProductionDetails } = this.props;
    submitProductionDetails(lineIdx, lineId, productionDetails, toDeleteProductionDetailsIds, productionDetailsKey);
  };

  handlePriceAdjustmentsChange = (lineIdx, lineId, priceAdjustments, toDeletePriceAdjustmentIds) => {
    const { submitPriceAdjustments } = this.props;
    if (submitPriceAdjustments) submitPriceAdjustments(lineIdx, lineId, priceAdjustments, toDeletePriceAdjustmentIds);
  };

  handleToggleCartonWeight = () => {
    const { isCartonWeightRequired } = this.state;
    this.setState({ isCartonWeightRequired: !isCartonWeightRequired });
  };

  handleOnBlur = (name, index, value) => {
    const { onPPURoundingDecimal, onPPUChangeValue, line } = this.props;

    const currentValue = get(line, name);
    if (currentValue === undefined || !currentValue) {
      // eslint-disable-next-line no-param-reassign
      value = 0;
      onPPUChangeValue(name, index, value);
    }
    onPPURoundingDecimal(name, index, value);
  };

  handleCLChange = (lineIdx, fieldName, value) => {
    const { submitCLResults } = this.props;
    submitCLResults(lineIdx, fieldName, value);
    this.handleUpdateLine(fieldName, lineIdx, value);
  }

  handleTemperatureChanges = (lineIdx, fieldName, value) => {
    const { submitTemperatures } = this.props;
    submitTemperatures(lineIdx, fieldName, value);
    this.handleUpdateLine(fieldName, lineIdx, value);
  }

  render() {
    const {
      buyUnitOfMeasureId,
      buyCurrency,
      hasExpectedProductionDate,
      ingredientsOptions,
      isInEditMode,
      formType,
      form,
      line,
      lineIndices,
      onPPURoundingDecimal,
      onPPUChangeValue,
      purchaseOrderId,
      purchaseOrderIndex,
      requiredFields,
      unitsOfMeasure,
      sellCurrency,
      sellUnitOfMeasureId,
      isChilled,
      packerPlantId,
      purchasingOffice,
      configs,
      packageWeights,
      productSpecifications,
      requiredFieldsLabels,
      isUSADomestic,
      isChicagoForm,
      priceTypes,
      endUsers,
      isLocked
    } = this.props;
    
    const { isCartonWeightRequired, expandHedgeItems } = this.state;
    if (!ingredientsOptions.find(igdt => igdt.value === line.inputProductUid) && line.inputProductUid) {
      ingredientsOptions.push({
        value: line.inputProductUid,
        delisted: true,
        label: line.inputProduct.name,
        cl: line.inputProduct.cl,
      });
    }
    const { buyQuantity, sellQuantity, buyPricePerUnit, sellPricePerUnit, cosPricePerUnit, recvQuantity } = line;
    const index = line.idx;
    const isLast = index === lineIndices[lineIndices.length - 1];
    const hidePopconfirm = lineIndices.length === 1;
    const isFrozenProduct = !isChilled;

    const ingredient = ingredientsOptions.find(igdt => igdt.value === line.inputProductUid);

    let requiredFieldErrors = [];
    let requiredFieldNames = [];
    // TODO make one format for capturing errors
    // check for the errors from multiple po creation
    if (purchaseOrderIndex) {
      requiredFieldErrors = get(requiredFields, `pos.${purchaseOrderIndex}.lines.${index}`, []);
      requiredFieldNames = get(requiredFieldsLabels, `pos.${purchaseOrderIndex}.lines.${index}`, []);
    }
    // if not check for errors in edit form
    if (!requiredFieldErrors.length) {
      requiredFieldErrors = get(requiredFields, `lines[${line.id}]`, []);
    }
    if (!requiredFieldNames.length) requiredFieldNames = get(requiredFieldsLabels, `lines[${line.id}]`, []);

    const isRecvQuantityEditable = isQuantityReceivedEditable(configs);
    const isManualPriceStrategyValue = isManualPriceStrategyByConfigsAndEndUser(configs, endUsers, form.endUserId);
    const poHedgingEnabled = isPoHedgingEnabled(configs);

    const specificationOptions = productSpecifications.map(el => {
      return { value: convertToSnakeCase(el.uid), label: el.name };
    });

    // const internalPoLabel = getInternalPoLabel(configs);
    const specificationValues = specificationOptions?.filter(el => line.specifications.includes(el.value));

    const priceTypeOptions = [];

    priceTypes.forEach(element => {
      const capitalized = element.value.charAt(0).toUpperCase() + element.value.slice(1);
      priceTypeOptions.push({ value: element.value, label: capitalized });
    });

    return (
      <VStack align="stretch" spacing="30px" backgroundColor="inherit">
        <HStack width="100%" backgroundColor="inherit">
          <Text
            as="p"
            fontSize="15px"
            font-size="15px"
            textTransform="uppercase"
            borderBottomWidth="1px"
            width="100%"
            textAlign="center"
            lineHeight="0.1em"
            // marginTop="-27px"
            backgroundColor="inherit"
          >
            <Box as="span" backgroundColor="inherit" padding="0 15px">
              Purchase Order Line #{index}
            </Box>
          </Text>
          {isInEditMode && (
            <>
              {/* <!-- CANCEL LINE BUTTON --> */}
              {!hidePopconfirm && (
                <Popover>
                  {({ onClose }) => (
                    <>
                      <PopoverTrigger>
                        <IconButton icon={<TrashOutlineIconSVG />} disabled={!isInEditMode} hidden={!isInEditMode} />
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody p={6}>Are you sure you want to delete this line?</PopoverBody>
                        <PopoverFooter>
                          <ButtonGroup spacing={2}>
                            <Button bg="primary.900" color="white" onClick={this.handleRemoveLine}>
                              Yes
                            </Button>
                            <Button onClick={onClose}>No</Button>
                          </ButtonGroup>
                        </PopoverFooter>
                      </PopoverContent>
                    </>
                  )}
                </Popover>
              )}
              {isLast && (
                <IconButton
                  onClick={this.handleAddLine}
                  disabled={!isInEditMode}
                  hidden={!isInEditMode}
                  icon={<i className="la la-plus international-order-line__icon--orange" />}
                  colorScheme="actionSecondary"
                  height="35px"
                  width="35px"
                  borderRadius="18px"
                />
              )}
            </>
          )}
        </HStack>
        {!line.active ? (
          <Flex justifyContent="flex-end">
            <Tag colorScheme="red" variant="solid" borderRadius="2px">
              Canceled
            </Tag>
          </Flex>
        ) : (
          ''
        )}
        <Wrap spacing="56px" paddingX="10px" width="100%">
          {/* <!-- INGREDIENT SELECT --> */}
          <CustomFormControl width="305px">
            <POFormFieldLabel
              label="Product"
              htmlFor="select-input-product"
              fieldName="inputProductUid"
              className="international-order-line__label"
              isRequired={requiredFieldNames.includes('inputProductUid')}
              tooltip="Select the specific product from the dropdown list."
            />
            <SelectField
              isDisabled={!isInEditMode}
              name="select-input-product"
              defaultValue={line.inputProductUid}
              options={ingredientsOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
              className={requiredFieldErrors.includes('inputProductUid') ? 'error' : ''}
              onChange={e => {
                this.handleUpdateLine('inputProductUid', index, e);
              }}
              controlStyles={{ height: '38px' }}
            />
          </CustomFormControl>
          {/* INGREDIENT DESCRIPTION */}
          {purchaseOrderId && (
            <CustomFormControl width="305px">
              <POFormFieldLabel
                label="Description"
                htmlFor="select-input-ingredient-description"
                className="international-order-line__input-product-desc"
                tooltip="Enter a detailed description of the product."
              />
              <Input
                height="40px"
                disabled={!isInEditMode}
                name="select-ingredient-description"
                value={line.packerIngredientDescription || get(line, 'input_product.description') || ''}
                onChange={e => {
                  this.handleUpdateLine('packerIngredientDescription', index, e.target.value);
                }}
              />
            </CustomFormControl>
          )}
          <CustomFormControl width="305px">
            {/* <!-- CHECKBOX GROUP --> */}
            <POFormFieldLabel
              label="Specifications"
              htmlFor="select-specifications"
              className="international-order-line__specifications"
              tooltip="Provide the product specifications."
            />
            <SelectField
              {...{
                className: 'international-order-line__select',
                isClearable: true,
                isDisabled: !isInEditMode,
                isSearchable: true,
                closeMenuOnSelect: false,
                removeSelected: true,
                isMulti: true,
                name: 'select-specifications',
                defaultValue: specificationValues,
                onChange: e => this.handleUpdateLine('specifications', index, e),
                options: specificationOptions,
              }}
            />
          </CustomFormControl>
          {((isUSADomestic && !isFrozenProduct) || form.status === Constants.ORDER_STATUSES.DRAFT) && (
            <>
              <CustomFormControl>
                <POFormFieldLabel
                  label="Order Type"
                  tooltip="Specifies the nature of the order (Spot, Contract, Formula, Other, NOF)."
                  htmlFor="orderType"
                  fieldName="orderType"
                  className="international-order-line__label"
                />
                <SelectField
                  {...{
                    className: 'international-order-line__select',
                    isDisabled: !isInEditMode,
                    closeMenuOnSelect: false,
                    isMulti: false,
                    name: 'select-orderType',
                    defaultValue: line.priceType,
                    value: priceTypeOptions.find(option => option.value === line.priceType) || null,
                    onChange: e => this.handleUpdateLine('priceType', index, e.value),
                    options: priceTypeOptions,
                  }}
                />
              </CustomFormControl>

              {form.status !== Constants.ORDER_STATUSES.CANCELLED &&
                form.status !== Constants.ORDER_STATUSES.ORDERED &&
                form.status !== Constants.ORDER_STATUSES.PENDING &&
                form.status !== Constants.ORDER_STATUSES.ON_HOLD &&
                line.priceType === 'formula' && (
                  <>
                    <CustomFormControl>
                      <POFormFieldLabel
                        label="Formula Name"
                        htmlFor="formulaName"
                        fieldName="formulaName"
                        className="international-order-line__label"
                      />
                      <SelectField
                        {...{
                          className: 'international-order-line__select',
                          isDisabled: !isInEditMode,
                          isSearchable: true,
                          closeMenuOnSelect: false,
                          isMulti: false,
                          name: 'select-formulaName',
                          defaultValue: line.formulaName,
                          onChange: e => this.handleUpdateLine('formulaName', index, e.value),
                          options: [
                            {
                              value: 'USDA National Weds 7d|usda,3,7',
                              label: 'USDA National Weds 7d',
                            },
                            {
                              value: 'USDA National Fri 7d|usda,5,7',
                              label: 'USDA National Fri 7d',
                            },
                            {
                              value: 'USDA National Weds 5d|usda,3,5',
                              label: 'USDA National Weds 5d',
                            },
                            {
                              value: 'USDA National Fri 5d|usda,5,5',
                              label: 'USDA National Fri 5d',
                            },
                          ],
                        }}
                      />
                    </CustomFormControl>

                    <CustomFormControl>
                      <POFormFieldLabel
                        label="Market"
                        htmlFor="formulaMarket"
                        fieldName="formulaMarket"
                        className="international-order-line__label"
                      />
                      <SelectField
                        {...{
                          className: 'international-order-line__select',
                          isDisabled: !isInEditMode,
                          isSearchable: true,
                          closeMenuOnSelect: false,
                          isMulti: false,
                          name: 'select-formulaMarket',
                          defaultValue: line.formulaMarket,
                          onChange: e => this.handleUpdateLine('formulaMarket', index, e.value),
                          options: [
                            {
                              value: 'USDA National',
                              label: 'USDA National',
                            },
                          ],
                        }}
                      />
                    </CustomFormControl>

                    <CustomFormControl>
                      <POFormFieldLabel
                        label="Day"
                        htmlFor="formulaDay"
                        fieldName="formulaDay"
                        className="international-order-line__label"
                      />
                      <SelectField
                        {...{
                          className: 'international-order-line__select',
                          isDisabled: !isInEditMode,
                          isSearchable: true,
                          closeMenuOnSelect: false,
                          isMulti: false,
                          name: 'select-formulaDay',
                          defaultValue: line.formulaDay,
                          onChange: e => this.handleUpdateLine('formulaDay', index, e.value),
                          options: [
                            {
                              value: '',
                              label: '(none)',
                            },
                            {
                              value: '1',
                              label: 'Mon',
                            },
                            {
                              value: '2',
                              label: 'Tue',
                            },
                            {
                              value: '3',
                              label: 'Wed',
                            },
                            {
                              value: '4',
                              label: 'Thu',
                            },
                            {
                              value: '5',
                              label: 'Fri',
                            },
                            {
                              value: '6',
                              label: 'Sat',
                            },
                            {
                              value: '0',
                              label: 'Sun',
                            },
                          ],
                        }}
                      />
                    </CustomFormControl>

                    <CustomFormControl>
                      <POFormFieldLabel
                        label="Avg. Days"
                        htmlFor="formulaDaysAveraged"
                        fieldName="formulaDaysAveraged"
                        className="international-order-line__label"
                      />
                      <Input
                        className="form-control"
                        value={line.formulaDaysAveraged || ''}
                        disabled={!isInEditMode}
                        placeholder="Add notes"
                        type="number"
                        step=".01"
                        max="0"
                        onChange={e => this.handleUpdateLine('formulaDaysAveraged', index, e.target.value)}
                        backgroundColor={!isInEditMode ? 'inherit' : 'white'}
                      />
                    </CustomFormControl>

                    <CustomFormControl>
                      <POFormFieldLabel
                        label="Basis"
                        htmlFor="formulaBasis"
                        fieldName="formulaBasis"
                        className="international-order-line__label"
                      />
                      <Input
                        className="form-control"
                        value={line.formulaBasis || ''}
                        disabled={!isInEditMode}
                        placeholder="Add notes"
                        type="number"
                        step=".01"
                        max="0"
                        onChange={e => this.handleUpdateLine('formulaBasis', index, e.target.value)}
                        backgroundColor={!isInEditMode ? 'inherit' : 'white'}
                      />
                    </CustomFormControl>
                  </>
                )}

              {form.status !== Constants.ORDER_STATUSES.CANCELLED &&
                form.status !== Constants.ORDER_STATUSES.ORDERED &&
                form.status !== Constants.ORDER_STATUSES.PENDING &&
                form.status !== Constants.ORDER_STATUSES.ON_HOLD &&
                line.priceType === 'contract' && (
                  <>
                    <CustomFormControl>
                      <POFormFieldLabel
                        label="Contract Start Date"
                        htmlFor="contract_start_date"
                        className="international-order-line__label"
                        fieldName="contractStartDate"
                      />
                      <DateTimeWithTrappedFocus
                        id="contract_start_date"
                        name="contract_start_date"
                        inputProps={{
                          className: 'form-control m-input',
                          disabled: !isInEditMode,
                        }}
                        closeOnSelect
                        value={line.contractStartDate}
                        onChange={e =>
                          this.handleUpdateLine(
                            'contractStartDate',
                            index,
                            e._isAMomentObject ? e.format('YYYY-MM-DD') : null // eslint-disable-line no-underscore-dangle
                          )
                        }
                        className={requiredFieldErrors.includes('contractStartDate') ? 'error' : ''}
                        timeFormat={false}
                        dateFormat="YYYY-MM-DD"
                      />
                    </CustomFormControl>

                    <CustomFormControl>
                      <POFormFieldLabel
                        label="Contract End Date"
                        htmlFor="contract_end_date"
                        className="international-order-line__label"
                        fieldName="contractEndDate"
                      />
                      <DateTimeWithTrappedFocus
                        id="contract_end_date"
                        name="contract_end_date"
                        inputProps={{
                          className: 'form-control m-input',
                          disabled: !isInEditMode,
                        }}
                        closeOnSelect
                        value={line.contractEndDate}
                        onChange={e =>
                          this.handleUpdateLine(
                            'contractEndDate',
                            index,
                            e._isAMomentObject ? e.format('YYYY-MM-DD') : null // eslint-disable-line no-underscore-dangle
                          )
                        }
                        className={requiredFieldErrors.includes('contractEndDate') ? 'error' : ''}
                        timeFormat={false}
                        dateFormat="YYYY-MM-DD"
                      />
                    </CustomFormControl>
                  </>
                )}

              <CustomFormControl width="577px">
                <POFormFieldLabel
                  label="Order Type Notes"
                  htmlFor="orderTypeNotes"
                  fieldName="orderTypeNotes"
                  className="international-order-line__label"
                />
                <Input
                  className="form-control"
                  value={line.orderTypeNotes || ''}
                  disabled={!isInEditMode}
                  placeholder="Add notes"
                  onChange={e => this.handleUpdateLine('orderTypeNotes', index, e.target.value)}
                  backgroundColor={!isInEditMode ? 'inherit' : 'white'}
                />
              </CustomFormControl>

              <CustomFormControl>
                <POFormFieldLabel
                  label="Standing"
                  htmlFor="standing"
                  fieldName="standing"
                  className="international-order-line__label"
                />
                <SelectField
                  {...{
                    className: 'international-order-line__select',
                    isDisabled: !isInEditMode,
                    isSearchable: true,
                    closeMenuOnSelect: false,
                    isMulti: false,
                    name: 'select-standing',
                    defaultValue: line.standing,
                    onChange: e => this.handleUpdateLine('standing', index, e.value),
                    options: [
                      {
                        value: 'yes',
                        label: 'Yes',
                      },
                      {
                        value: 'no',
                        label: 'No',
                      },
                    ],
                  }}
                />
              </CustomFormControl>
            </>
          )}
          
          {!isChilled && (
            <CustomFormControl width="120px">
              <POFormFieldLabel
                label="Carton Weight"
                htmlFor="isCartonWeightRequired"
                className="date-section__label"
                tooltip="Indicate whether the carton weight is applicable (Yes or No)."
              />
              <Switch
                id="isCartonWeightRequired"
                onChange={this.handleToggleCartonWeight}
                defaultChecked={isCartonWeightRequired}
                colorScheme="actionPrimary"
                size="lg"
                marginY="5px"
                isDisabled={!isInEditMode}
              />
            </CustomFormControl>
          )}
        </Wrap>
        <Wrap spacing="56px" paddingX="10px" width="100%">
          {/* <!--- PACKAGE COUNT ----> */}
          {isCartonWeightRequired && !isChilled && (
            <CustomFormControl width="166px">
              <POFormFieldLabel
                label="Cartons Number"
                htmlFor="packageCount"
                className="international-order-line__specifications"
                fieldName="cartonsNumber"
                isRequired={isCartonWeightRequired}
                tooltip="Specify the number of cartons in the order."
              />
              <Input
                name="packageCount"
                type="number"
                value={line.packageCount || ''}
                disabled={!isInEditMode}
                onChange={e => this.handleUpdateLine('packageCount', index, e.target.value)}
                onWheelCapture={e => {
                  e.target.blur();
                }}
                height="40px"
                className={
                  requiredFieldErrors.includes('buyQuantity') || requiredFieldErrors.includes('sellQuantity')
                    ? 'error'
                    : ''
                }
              />
              <input type="hidden" name="packageType" value={line.packageType || ''} />
            </CustomFormControl>
          )}
          {/* <!-- QUANTITY FIELDS --> */}
          <QuantityFields
            {...{
              buyQuantity,
              buyUnitOfMeasureId,
              errors: requiredFieldErrors.length ? requiredFieldErrors : null,
              requiredFieldNames,
              index,
              isInEditMode,
              onLineValueChange: this.handleUpdateLine,
              sellQuantity,
              sellUnitOfMeasureId,
              unitsOfMeasure,
              isFrozenProduct,
              ingredient,
              packerUid: packerPlantId,
              purchasingOffice,
              recvQuantity,
              isRecvQuantityEditable,
              packageWeights,
              purchaseOrderId,
              isCartonWeightRequired,
            }}
          />
        </Wrap>
        <Wrap spacing="19px" paddingX="10px" width="100%">
          <HStack spacing="15px">
            {isUSADomestic && !isFrozenProduct ? (
              <CustomFormControl width="165px">
                <POFormFieldLabel
                  label="FOB Price"
                  htmlFor="fobPricePerUnit"
                  fieldName="fobPricePerUnit"
                  className="international-order-line__label"
                  tooltip="Captures the Free on Board (FOB) price at the point of shipment."
                />
                <HStack spacing="7px">
                  <Input
                    id="pricePerUnit"
                    name="pricePerUnit"
                    pattern={Constants.PRICE_NUMBER_FIELD_PATTERN}
                    isDisabled={!buyCurrency || !isInEditMode}
                    borderColor={requiredFieldErrors.includes('pricePerUnit') ? 'red' : 'inherit'}
                    type="number"
                    value={line.pricePerUnit}
                    onFocus={event => event.target.select()}
                    step="0.0001"
                    onChange={e => onPPUChangeValue('pricePerUnit', index, e.target.value)}
                    onBlur={e => this.handleOnBlur('pricePerUnit', index, e.target.value)}
                    width="125px"
                    height="40px"
                    onWheel={e => e.target.blur()}
                  />
                  <FormLabel htmlFor="fobPrice">{buyCurrency}</FormLabel>
                </HStack>
              </CustomFormControl>
            ) : (
              ''
            )}
            {/* <!-- EDIT PO -> GROUP HOUSED PREMIUM ONLY FOR PRODUCT TYPE PORK  --> */}           
            {(form.productType === Constants.PRODUCT_TYPE_LIST.PORK && isUSADomestic) &&  (         
            <CustomFormControl width="200px">             
              <POFormFieldLabel
                label="Group Housed Premium"
                htmlFor="groupHousedPremium"
                fieldName="groupHousedPremium"
                className="international-order-line__label"
                tooltip="Indicates the Cost of Group Housed Premium for Group Housed product"
              />          
              
              <HStack spacing="7px">               
                <Input
                  id="groupHousedPremiumRate"
                  name="groupHousedPremiumRate"
                  pattern={Constants.PRICE_NUMBER_FIELD_PATTERN}
                  isDisabled={!isInEditMode}
                  // borderColor={requiredFieldErrors.includes('pricePerUnit') ? 'red' : 'inherit'}
                  type="number"
                  value={line.groupHousedPremiumRate}
                  onFocus={event => event.target.select()}
                  step="0.0001"
                  onChange={e => onPPUChangeValue('groupHousedPremiumRate', index, e.target.value)}
                  onBlur={e => this.handleOnBlur('groupHousedPremiumRate', index, e.target.value)}
                  width="125px"
                  height="40px"
                  onWheel={e => e.target.blur()}
                />
                <FormLabel htmlFor="groupHousedPremium">{buyCurrency}</FormLabel>
              </HStack>
            </CustomFormControl>
            )}
            <CustomFormControl width="165px">
              <POFormFieldLabel
                label="Buy Unit Price"
                htmlFor="buyPricePerUnit"
                fieldName="buyPricePerUnit"
                className="international-order-line__label"
                tooltip="Specifies the price based on the values selected in Currency (Buy)."
              />
              <HStack spacing="7px">
                <Input
                  id="buyPricePerUnit"
                  name="buyPricePerUnit"
                  pattern={Constants.PRICE_NUMBER_FIELD_PATTERN}
                  isDisabled={!buyCurrency || !isInEditMode}
                  borderColor={requiredFieldErrors.includes('buyPricePerUnit') ? 'red' : 'inherit'}
                  type="number"
                  value={buyPricePerUnit}
                  onFocus={event => event.target.select()}
                  step="0.0001"
                  onChange={e => onPPUChangeValue('buyPricePerUnit', index, e.target.value)}
                  onBlur={e => this.handleOnBlur('buyPricePerUnit', index, e.target.value)}
                  width="125px"
                  height="40px"
                  onWheel={e => e.target.blur()}
                />
                <FormLabel htmlFor="buyPricePerUnit">{buyCurrency}</FormLabel>
              </HStack>
            </CustomFormControl>
            {/* <!-- COS UNIT PRICE --> */}
            {formType === 'editForm' && !isManualPriceStrategyValue && (
              <CustomFormControl width="165px">
                <POFormFieldLabel
                  label="COS Unit Price"
                  htmlFor="cosPricePerUnit"
                  fieldName="cosPricePerUnit"
                  className="international-order-line__label"
                  tooltip="Specifies the price based on the values selected in Currency (Buy)."
                />
                <HStack spacing="7px">
                  <Input
                    id="cosPricePerUnit"
                    name="cosPricePerUnit"
                    pattern={Constants.PRICE_NUMBER_FIELD_PATTERN}
                    isDisabled
                    borderColor={requiredFieldErrors.includes('buyPricePerUnit') ? 'red' : 'inherit'}
                    type="number"
                    onWheel={e => e.target.blur()}
                    value={cosPricePerUnit || ''}
                    step="0.0001"
                    onChange={e => onPPUChangeValue('cosPricePerUnit', index, e.target.value)}
                    onBlur={e => onPPURoundingDecimal('cosPricePerUnit', index, e.target.value)}
                    width="125px"
                    height="40px"
                  />
                  <FormLabel htmlFor="cosPricePerUnit">{sellCurrency}</FormLabel>
                </HStack>
              </CustomFormControl>
            )}
          </HStack>
          {/* <!-- PRICE PER UNIT SELL --> */}
          {(formType === 'editForm' || isManualPriceStrategyValue) && (
            <CustomFormControl width="165px">
              <POFormFieldLabel
                label="Sell Unit Price"
                htmlFor="sellPricePerUnit"
                fieldName="sellPricePerUnit"
                className="international-order-line__label"
                tooltip="Specifies the price based on the values selected in Currency (Sell)."
              />
              <HStack spacing="7px">
                <Input
                  id="sellPricePerUnit"
                  name="sellPricePerUnit"
                  pattern={Constants.PRICE_NUMBER_FIELD_PATTERN}
                  isDisabled={!buyCurrency || !isInEditMode || !isManualPriceStrategyValue}
                  borderColor={requiredFieldErrors.includes('sellPricePerUnit') ? 'red' : 'inherit'}
                  type="number"
                  value={sellPricePerUnit}
                  onWheel={e => e.target.blur()}
                  step="0.0001"
                  onChange={e => onPPUChangeValue('sellPricePerUnit', index, e.target.value)}
                  onBlur={e => this.handleOnBlur('sellPricePerUnit', index, e.target.value)}
                  width="125px"
                  height="40px"
                />
                <FormLabel htmlFor="cosPricePerUnit">{sellCurrency}</FormLabel>
              </HStack>
            </CustomFormControl>
          )}
          {hasExpectedProductionDate && (
            <CustomFormControl>
              <POFormFieldLabel
                label="Expected Production Date"
                htmlFor="fabrication-date"
                className="international-order-line__label"
                fieldName="expectedProductionDate"
                isRequired={requiredFieldNames.includes('expectedProductionDate')}
                tooltip="Indicates the anticipated date when the product will be manufactured."
              />
              <DateTimeWithTrappedFocus
                id="fabrication-date"
                name="fabrication-date"
                inputProps={{
                  className: 'form-control m-input',
                  disabled: !isInEditMode,
                }}
                closeOnSelect
                value={line.expectedProductionDate}
                onChange={e =>
                  this.handleUpdateLine(
                    'expectedProductionDate',
                    index,
                    e._isAMomentObject ? e.format('YYYY-MM-DD') : null // eslint-disable-line no-underscore-dangle
                  )
                }
                className={requiredFieldErrors.includes('expectedProductionDate') ? 'error' : ''}
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
              />
            </CustomFormControl>
          )}
          {/* <!-- Multiple Prod dates --> */}
        </Wrap>
        {isUSADomestic && !isFrozenProduct ? (
          <Box paddingX="10px">
            <PriceAdjustment
              {...{
                unitsOfMeasure,
                line,
                isInEditMode,
                index,
                onChange: this.handlePriceAdjustmentsChange,
                isFrozenProduct,
                isLocked
              }}
            />
          </Box>
        ) : (
          ''
        )}
        {purchaseOrderId && (
          <Box paddingX="10px">
            <ProductionDetails
              {...{
                unitsOfMeasure,
                line,
                isInEditMode,
                index,
                onChange: this.handleProductionDetailsChange,
                isFrozenProduct,
                isUSADomestic,
                isChicago: isChicagoForm,
                buyUnitOfMeasureId: form.buyUnitOfMeasureId,
                onUpdateLine: this.handleUpdateLine,
              }}
            />
          </Box>
        )}
        {purchaseOrderId && poHedgingEnabled && (
          <VStack paddingX="10px" align="stretch">
            <HStack justifyContent="space-between" color="accent.one.default" width="150px">
              <Text as="p" fontWeight="medium" color="inherit">
                Hedge Details
              </Text>
              <IconButton
                variant="unstyled"
                minWidth="18px"
                height="fit-content"
                padding="0px"
                fontSize="18px"
                icon={expandHedgeItems ? <ChevronDownIcon /> : <ChevronRightIcon />}
                onClick={() => this.setState({ expandHedgeItems: !expandHedgeItems })}
              />
            </HStack>
            {expandHedgeItems && (
              <Wrap spacing="54px">
                <CustomFormControl width="125px">
                  <POFormFieldLabel
                    label="Hedge"
                    htmlFor="hedge"
                    fieldName="hedge"
                    className="international-order-line__col"
                    isRequired={requiredFieldNames.includes('hedge')}
                  />
                  <Input
                    name="hedge"
                    type="number"
                    value={line?.hedgeItems?.hedge || ''}
                    disabled={!isInEditMode}
                    onWheel={e => e.target.blur()}
                    className={requiredFieldErrors.includes('hedge') ? 'form-control error' : 'form-control'}
                    onChange={e => this.handleUpdateLine('hedgeItems.hedge', index, e.target.value)}
                    onBlur={e => e.target.value === '' && this.handleUpdateLine('hedgeItems.hedge', index, null)}
                    backgroundColor="white"
                  />
                </CustomFormControl>
                <CustomFormControl width="125px">
                  <POFormFieldLabel
                    label="Hedge CHG"
                    htmlFor="hedgeChg"
                    fieldName="hedgeChg"
                    className="international-order-line__col"
                    isRequired={requiredFieldNames.includes('hedgeChg')}
                  />
                  <Input
                    name="hedgeChg"
                    type="number"
                    onWheel={e => e.target.blur()}
                    value={line?.hedgeItems?.hedgeChg || ''}
                    disabled={!isInEditMode}
                    className={requiredFieldErrors.includes('hedgeChg') ? 'form-control error' : 'form-control'}
                    onChange={e => this.handleUpdateLine('hedgeItems.hedgeChg', index, e.target.value)}
                    onBlur={e => e.target.value === '' && this.handleUpdateLine('hedgeItems.hedgeChg', index, null)}
                    backgroundColor="white"
                  />
                </CustomFormControl>
                <CustomFormControl width="125px">
                  <POFormFieldLabel
                    label="Hedge INT"
                    htmlFor="hedgeInt"
                    fieldName="hedgeInt"
                    className="international-order-line__col"
                    isRequired={requiredFieldNames.includes('hedgeInt')}
                  />
                  <Input
                    name="hedgeInt"
                    type="number"
                    onWheel={e => e.target.blur()}
                    value={line?.hedgeItems?.hedgeInt || ''}
                    disabled={!isInEditMode}
                    className={requiredFieldErrors.includes('hedgeInt') ? 'form-control error' : 'form-control'}
                    onChange={e => this.handleUpdateLine('hedgeItems.hedgeInt', index, e.target.value)}
                    onBlur={e => e.target.value === '' && this.handleUpdateLine('hedgeItems.hedgeInt', index, null)}
                    backgroundColor="white"
                  />
                </CustomFormControl>
                <CustomFormControl width="125px">
                  <POFormFieldLabel
                    label="Hedge LF"
                    htmlFor="hedgeLf"
                    fieldName="hedgeLf"
                    className="international-order-line__col"
                    isRequired={requiredFieldNames.includes('hedgeLf')}
                  />
                  <Input
                    name="hedgeLf"
                    type="number"
                    onWheel={e => e.target.blur()}
                    value={line?.hedgeItems?.hedgeLf || ''}
                    disabled={!isInEditMode}
                    className={requiredFieldErrors.includes('hedgeLf') ? 'form-control error' : 'form-control'}
                    onChange={e => this.handleUpdateLine('hedgeItems.hedgeLf', index, e.target.value)}
                    onBlur={e => e.target.value === '' && this.handleUpdateLine('hedgeItems.hedgeLf', index, null)}
                    backgroundColor="white"
                  />
                </CustomFormControl>
              </Wrap>
            )}
          </VStack>
        )}
        {purchaseOrderId && (
          <><Box paddingX="10px">
            <CL
              line={line}
              lineIndex={index}
              clResults={line.clResults}
              isInEditMode={isInEditMode}
              clType={line.opClType}
              opAverageThirdPartyClResult={line.opAverageThirdPartyClResult}
              handleCLChange={this.handleCLChange} />
          </Box><Box paddingX="10px">
              <Temperatures
                line={line}
                lineIndex={index}
                temperatures={line.temperatures}
                isInEditMode={isInEditMode}
                opTempType={line.opTempType}
                handleTemperatureChanges={this.handleTemperatureChanges} />
            </Box></>
        )}
      </VStack>
    );
  }
}

export default InternationalOrderLine;
